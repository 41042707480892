<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Cama - Ver</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form">
              <b-row>

                <b-col md="3">
                  <b-row>
                    <b-col md="4"></b-col>
                    <b-col md="4"><b-card-img class="" :src="url_base + bed.photo"></b-card-img></b-col>
                  </b-row>
            
                </b-col>

                <b-col md="9">
                  <b-row>

                        <b-col md="10">
                          <b-form-group label="Nombre:">
                            <b-form-input  disabled type="text" v-model="bed.name" ></b-form-input>
                          </b-form-group>
                        </b-col>

                        <b-col md="2">
                          <b-form-group label="Estado:">
                            <b-form-select disabled v-model="bed.state" :options="state"></b-form-select>
                          </b-form-group>
                        </b-col>

                        
                        <b-col md="5"></b-col>
                        <b-col md="2">
                          <b-link class="btn form-control btn-primary" :to="{ path: '/cama/listar' }" append>REGRESAR</b-link>
                        </b-col>

                  </b-row>

                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>

  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  components:{
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  props: ["id_bed"],
  data() {
    return {
      isLoading: false,
      module: "bed",

      role: 5,
      bed: {
        id_bed: "",
        name: "",
        photo: "",
        photo_change: "",
        state: 1,
      },
      photo: null,
      state:[
        {value:0,text:'Inactivo'},
        {value:1,text:'Activo'},
      ],
      
     
      validate: false,
    };
  },
  mounted() {
    this.ViewBed();
  },
  methods: {
    ViewBed,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};
function ViewBed() {
  let id_bed = je.decrypt(this.id_bed);
  let me = this;
  let url = this.url_base + "bed/view/" + id_bed;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module,role: this.role,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.bed.id_bed = response.data.result.id_bed;
        me.bed.name = response.data.result.name;
        me.bed.photo = response.data.result.photo;
        me.bed.state = response.data.result.state;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

</script>
